import styled from "@emotion/styled";
import React, { FC } from "react";
import Slider from "react-slick";
import Team from "../team/team";
import { teamData } from "../companySections/teamCardSection/teamCardSection";
import media from "../breakpoints";

interface Props {
  //settings: Object;
  //   subTitle: string;
  //   color: string;
  //   index: number;
}
function toMatrix(arr: any[], length: number): any[][] {
  let matrix = [];
  for (let i = 0, k = -1; i < arr.length; i++) {
    if (i % length === 0) {
      k++;
      matrix[k] = [];
    }
    (matrix[k] as any[]).push(arr[i]);
  }
  return matrix;
}

const TeamCarousel: FC<Props> = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  const cardsItems = toMatrix(teamData, 4);
  const CardsSlider = styled.div`
    .slick-dots li button::before {
      font-size: 11px;
      color: #d9dbe1 !important;
      opacity: 1 !important;
    }
    ul.slick-dots li button:hover::before {
      color: #273372 !important;
    }
    ul.slick-dots li button:focus::before {
      color: #141a39 !important;
    }
    ul.slick-dots li button:hover::before {
      color: #3147ff !important;
    }
    .slick-list {
      padding-bottom: 44px;
      margin: 0 auto;
      max-width: 1007px;
    }
    .title-parent {
      max-width: 585px;
      font-weight: 600;
      font-size: 34px;
      line-height: 44px;
      ${media("md")} {
        font-size: 28px;
        line-height: 38px;
      }
    }
  `;
  return (
    <CardsSlider className="mb-16 md:mb-24">
      <p className="title-parent font-Inter text-blue-darken tracking-tight text-3xl font-semibold  pt-16 mx-auto mb-6 md:text-3xl md:text-center md:tracking-tighter md:mb-10 md:mr-auto md:ml-auto md:px-2">
        Meet our team
      </p>
      <Slider {...settings}>
        {cardsItems.map((items, index) => (
          <div
            key={index}
            className="cards-parent flex flex-col justify-between min-w-full"
          >
            {items.map((item, i) => (
              <Team
                key={i}
                title={item.title}
                subTitle={item.subTitle}
                photo={item.photo}
                url={item.url}
              />
            ))}
          </div>
        ))}
      </Slider>
    </CardsSlider>
  );
};

export default TeamCarousel;
