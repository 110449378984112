import styled from "@emotion/styled";
import media from "../../breakpoints";

const TeamCardSectionWrapper = styled.section`
  overflow: hidden;
  .wrapper-parent {
    min-height: 628px;
  }
  .team-container {
    padding-left: 100px;
    padding-right: 100px;
    ${media("lg")} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export { TeamCardSectionWrapper };
