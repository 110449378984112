import React, { FC } from "react";
import { TeamWrapper } from "./team.styles";

interface Props {
  title: string;
  subTitle: string;
  photo: string;
  url: string;
}
const Team: FC<Props> = ({ title, subTitle, photo, url }) => {
  return (
    <TeamWrapper className="team-wrapper my-5 mx-3 lg:mx-1">
      <a
        href={url}
        className="team-parent bg-white flex flex-col justify-start py-7 px-5 rounded-2xl h-full w-full cursor-pointer md:py-5 md:flex-row md:flex-nowrap"
      >
        <img
          className="photo mb-5 rounded-full md:mr-5 md:mb-0"
          src={photo}
          width={72}
          height={72}
          alt={title}
        />
        <div>
          <p className="title font-Inter relative text-blue-accent text-lg font-semibold">
            {title}
          </p>
          <p className="subTitle font-Inter text-blue-darken text-base font-normal">
            {subTitle}
          </p>
        </div>
      </a>
    </TeamWrapper>
  );
};

export default Team;
