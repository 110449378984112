import React, { FC } from "react";
import { TeamSectionWrapper } from "./teamSection.styles";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Link } from "gatsby";
import TeamCardSection from "../teamCardSection/teamCardSection";
import TeamCarousel from "../../teamCarousel/teamCarousel";

interface Props {
  //   title: string;
}
const TeamSection: FC<Props> = () => {
  let isMobile = useMediaQuery("(max-width: 991.98px)");
  return (
    <TeamSectionWrapper className="font-Inter section-wrapper pb-52 md:pb-24">
      {isMobile ? <TeamCarousel /> : <TeamCardSection />}
      <div className="layout-width w-full flex flex-row justify-center">
        <Link
          to="/careers"
          className="font-Inter button-nav bg-transparent text-blue-accent text-sm font-medium w-48 h-10 text-center my-auto py-2 border-2 divide-solid border-blue-accent rounded-2xl whitespace-nowrap outline-none "
        >
          See openings
        </Link>
      </div>
    </TeamSectionWrapper>
  );
};

export default TeamSection;
