import { css } from "@emotion/react";
import mainCurves from "../../../images/company-curve.svg";
import mainCurvesmob from "../../../images/company_curve.svg";
import media from "../../breakpoints";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 645px;
  }
  .curves-parent {
    background: url(${mainCurves}) center no-repeat;
    width: 1520px;
    height: 507px;
    transform: translate(-79%, 0);
    ${media("md")} {
      width: 1118px;
      height: 373px;
      background: url(${mainCurvesmob}) center no-repeat;
      transform: translate(-66%, 126%);
    }
  }
  .image-parent img {
    max-width: 590px;
    width: 100%;
  }
  .title-parent {
    max-width: 575px;
    line-height: 54px;
    font-size: 44px;
    ${media("md")} {
      font-size: 34px;
      line-height: 44px;
    }
  }
  .subtitle-parent {
    max-width: 590px;
  }
  .btn-parent {
    max-width: 240px;
  }
  .investor2 {
    width: 174px;
    height: 55px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    ${media("md")} {
      margin-bottom: 2.75rem;
    }
  }
  .investor3 {
    width: 70px;
    height: 24px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
  .investor4 {
    width: 131px;
    height: 45px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
  .investor5 {
    width: 193px;
    height: 40px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export { MainSectionWrapper };
