import styled from "@emotion/styled";
import media from "../breakpoints";

const TeamWrapper = styled.div`
  width: 222px;
  height: 233px;
  ${media("md")} {
    width: 100%;
    max-width: 328px;
    height: auto;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .team-parent:hover,
  .team-parent:focus {
    box-shadow: 0px 20px 20px rgba(13, 40, 59, 0.2);
  }
  .title {
    max-width: 300px;
  }
  .subTitle {
    max-width: 300px;
  }
  img.photo {
    display: block;
    height: 72px;
    width: 72px;
  }
`;
export { TeamWrapper };
