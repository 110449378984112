import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import MainSection from "../components/companySections/mainSection/mainSection";
import WhySection from "../components/companySections/whySection/whySection";
import TeamSection from "../components/companySections/teamSection/teamSection";
import BlueSection from "../components/blueSetion/blueSection";

const CompanyPage = () => {
  const textArr = ["Move money", " fast, ", "without risk."];
  return (
    <Layout>
      <Seo title="Company" pathname="/company" />
      <main>
        <MainSection />
        <WhySection />
        <TeamSection />
        <BlueSection textArr={textArr} btnText={"Book a demo"} />
      </main>
    </Layout>
  );
};

export default CompanyPage;
