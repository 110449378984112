import React, { FC } from "react";
import { TeamCardSectionWrapper } from "./teamCardSection.styles";
import Team from "../../team/team";
import zahid from "../../../images/team/zahid.png";
import alex from "../../../images/team/alex.png";
import kazuki from "../../../images/team/kazuki.jpeg";
import soups from "../../../images/team/soups.jpg";
import teamAdi from "../../../images/team/adi.jpg";
import myles from "../../../images/team/myles.jpg";
import olga from "../../../images/team/olga.jpeg";
import alexey from "../../../images/team/alexey.jpeg";
import ryo from "../../../images/team/ryo_kato.jpeg";
import srikant from "../../../images/team/srikant.jpeg";
import andrew from "../../../images/team/andrew.jpeg";
import divakar from "../../../images/team/divakar_manoj.jpeg";
import suhail from "../../../images/team/suhail.jpeg";
import lauren from "../../../images/team/lauren.png";
import joseph from "../../../images/team/joseph.jpeg";
import he from "../../../images/team/he.png";
import muhammad from "../../../images/team/muhammad.png";
import andrew_chiarello from "../../../images/team/andrew_chiarello.jpg";
import ashton from "../../../images/team/ashton.jpg";
import bil from "../../../images/team/bil.jpg";
import chandni from "../../../images/team/chandni.jpg";
import duke from "../../../images/team/duke.jpg";
import garret from "../../../images/team/garret.jpg";
import greg from "../../../images/team/greg.jpg";
import IB from "../../../images/team/IB.jpg";
import jonathan from "../../../images/team/jonathan.jpg";
import pete from "../../../images/team/pete.jpg";
import raj from "../../../images/team/raj.jpg";
import ravi from "../../../images/team/ravi.jpg";
import rupam from "../../../images/team/rupam.jpg";
import adam from "../../../images/team/adam.png";
import alexp from "../../../images/team/alexp.png";
import chad from "../../../images/team/chad.png";
import chandan from "../../../images/team/chandan.png";
import christian from "../../../images/team/christian.png";
import citlali from "../../../images/team/citlali.png";
import daniella from "../../../images/team/daniella.png";
import david from "../../../images/team/david.png";
import eric from "../../../images/team/eric.png";
import noel from "../../../images/team/noel.png";
import ethan from "../../../images/team/ethan.png";
import fabricio from "../../../images/team/fabricio.png";
import giulia from "../../../images/team/giulia.png";
import heather from "../../../images/team/heather.png";
import jeffrie from "../../../images/team/jeffrie.png";
import joshua from "../../../images/team/joshua.png";
import krisan from "../../../images/team/krisan.png";
import martin from "../../../images/team/martin.png";
import michael from "../../../images/team/michael.png";
import nadya from "../../../images/team/nadya.png";
import nipun from "../../../images/team/nipun.png";
import phillip from "../../../images/team/phillip.png";
import shawn from "../../../images/team/shawn.png";
import simon from "../../../images/team/simon.png";
import sophia from "../../../images/team/sophia.png";
import thomas from "../../../images/team/thomas.png";

interface Props {
  //   title: string;
}

export const teamData = [
  {
    title: "Soups Ranjan",
    subTitle: "Co-Founder, CEO",
    photo: soups,
    url: "https://www.linkedin.com/in/soupsranjan/",
  },
  {
    title: "Zahid Shaikh",
    subTitle: "Co-Founder, Head of Product",
    photo: zahid,
    url: "https://www.linkedin.com/mwlite/in/zahidshaikh",
  },
  {
    title: "Aditya Goel",
    subTitle: "Co-Founder, Head of Strategy & Operations",
    photo: teamAdi,
    url: "https://www.linkedin.com/in/goeladitya/",
  },
  {
    title: "Alex Kushnir",
    subTitle: "Head of Commercial Development",
    photo: alex,
    url: "https://www.linkedin.com/in/alkushnir/",
  },
  {
    title: "Christian Kunkel",
    subTitle: "Head of Growth & Digital Marketing",
    photo: christian,
    url: "https://www.linkedin.com/in/christian-kunkel/",
  },
  {
    title: "Simon Taylor",
    subTitle: "Head of Content",
    photo: simon,
    url: "https://www.linkedin.com/in/sytaylor/",
  },
  {
    title: "Chad Pace",
    subTitle: "Head of Talent",
    photo: chad,
    url: "https://www.linkedin.com/in/chad-pace-1438a448",
  },
  {
    title: "Krisan Nichani",
    subTitle: "Head of Compliance",
    photo: krisan,
    url: "https://www.linkedin.com/in/krisan-nichani",
  },
  {
    title: "Muhammad Darr",
    subTitle: "Transaction Monitoring & Compliance Manager",
    photo: muhammad,
    url: "",
  },
  {
    title: "Myles Blumberg",
    subTitle: "Head of Sales",
    photo: myles,
    url: "https://www.linkedin.com/in/mylesblumberg/",
  },{
    title: "Madhukar Puniani",
    subTitle: "Head Of Partnerships",
    photo: duke,
    url: "https://www.linkedin.com/in/madhukarpuniani/",
  },
  {
    title: "Bil Corry",
    subTitle: "CISO",
    photo: bil,
    url: "https://www.linkedin.com/in/bilcorry/",
  },
  {
    title: "Ashton Summers",
    subTitle: "Director of Account Management",
    photo: ashton,
    url: "https://www.linkedin.com/in/ashton-summers-01/",
  },
  {
    title: "Lauren Johnson",
    subTitle: "Account Executive",
    photo: lauren,
    url: "https://www.linkedin.com/in/lauren-johnson-27629698/",
  },
  {
    title: "Martin Bruhat",
    subTitle: "Enterprise Sales Manager",
    photo: martin,
    url: "https://www.linkedin.com/in/martin-bruhat-698197106",
  },
  {
    title: "Eric Zimmerman",
    subTitle: "Head of Business Development",
    photo: eric,
    url: "https://www.linkedin.com/in/ericdzimmerman",
  },
  {
    title: "Philip Pan",
    subTitle: "Business Operations",
    photo: phillip,
    url: "https://www.linkedin.com/in/pfpan",
  },
  {
    title: "IB Koleosho",
    subTitle: "Legal Counsel",
    photo: IB,
    url: "https://www.linkedin.com/in/ibkoleosho/",
  },
  {
    title: "Joseph Quickle",
    subTitle: "Principal Solution Architect",
    photo: joseph,
    url: "https://www.linkedin.com/in/quickle/",
  },
  {
    title: "Olga Yatsenko",
    subTitle: "Head of Customer Success & Solution Architect",
    photo: olga,
    url: "https://www.linkedin.com/in/ollyyatsenko/",
  },
  {
    title: "Gregory Wilson",
    subTitle: "Head of Fraud Ops",
    photo: greg,
    url: "https://www.linkedin.com/in/gregory-wilson-a178b721/",
  },
  {
    title: "Jonathan Chiu",
    subTitle: "Implementation Manager",
    photo: jonathan,
    url: "https://www.linkedin.com/in/jchiupro/",
  },
  {
    title: "Alex Popelard",
    subTitle: "Implementation Manager",
    photo: alexp,
    url: "https://www.linkedin.com/in/alexpopelard",
  },
  {
    title: "Pete Walton",
    subTitle: "Lead Solutions Engineer",
    photo: pete,
    url: "https://www.linkedin.com/in/peter-walton/",
  },
  {
    title: "Andrew Chiarello",
    subTitle: "Account Executive",
    photo: andrew_chiarello,
    url: "https://www.linkedin.com/in/andrew-chiarello-8b6a72105/",
  },
  {
    title: "Chandni Raja",
    subTitle: "Human Capital Lead",
    photo: chandni,
    url: "https://www.linkedin.com/in/chandnihr/",
  },
  {
    title: "Ravi Loganathan",
    subTitle: "Head of Banking Relations",
    photo: ravi,
    url: "https://www.linkedin.com/in/raviloganathan/",
  },
  {
    title: "Shawn Ghuman",
    subTitle: "Business Operations",
    photo: shawn,
    url: "https://www.linkedin.com/in/shawn-ghuman",
  },
  {
    title: "Danielle Friedman",
    subTitle: "EA to CEO",
    photo: daniella,
    url: "https://www.linkedin.com/in/danielle-friedman",
  },
  {
    title: "Adam Schneebaum",
    subTitle: "Product Manager",
    photo: adam,
    url: "https://www.linkedin.com/in/adam-schneebaum/",
  },
  {
    title: "Joshua Borin",
    subTitle: "Business Operations Manager",
    photo: joshua,
    url: "https://www.linkedin.com/in/joshborin",
  },
  {
    title: "Nadya Hiskey",
    subTitle: "Senior Fraud Ops Investigator",
    photo: nadya,
    url: "https://www.linkedin.com/in/nadya-hiskey",
  },
  {
    title: "Heather DeMinck",
    subTitle: "ACH Operations Manager",
    photo: heather,
    url: "https://www.linkedin.com/in/heather-deminck-aap-aprp-7a8b6217a/",
  },
  {
    title: "Citlali Aguirre",
    subTitle: "Customer Support Specialist",
    photo: citlali,
    url: "https://www.linkedin.com/in/citlaliolarteaguirre",
  },
  {
    title: "Noel Alexander",
    subTitle: "Information Technology Engineer",
    photo: noel,
    url: "https://www.linkedin.com/in/noel-alexander-347984168/",
  },
  {
    title: "Giulia Donato",
    subTitle: "People Operations",
    photo: giulia,
    url: "https://www.linkedin.com/in/giuliamariedonato",
  },
  {
    title: "Kazuki Nishiura",
    subTitle: "Head of Engineering",
    photo: kazuki,
    url: "https://www.linkedin.com/in/kazuki-nishiura/",
  },
  {
    title: "Alexey Chernikov",
    subTitle: "Principal Software Engineer",
    photo: alexey,
    url: "https://www.linkedin.com/in/alexeychernikov/",
  },
  {
    title: "Srikant Rao",
    subTitle: "Senior ML Engineer",
    photo: srikant,
    url: "https://www.linkedin.com/in/srikantrao/",
  },
  {
    title: "Andrew Tausz",
    subTitle: "Senior ML Engineer",
    photo: andrew,
    url: "https://www.linkedin.com/in/andrew-tausz/",
  },
  {
    title: "Garett Brock",
    subTitle: "Senior Risk Data Analyst",
    photo: garret,
    url: "https://www.linkedin.com/in/gbrock16/",
  },
  {
    title: "Rupam Banerjee",
    subTitle: "Senior Risk Data Analyst",
    photo: rupam,
    url: "https://www.linkedin.com/in/rupam-banerjee3/",
  },
  {
    title: "Sophia Dai",
    subTitle: "Senior Risk Data Analyst",
    photo: sophia,
    url: "https://www.linkedin.com/in/sophiadai",
  },
  {
    title: "Thomas Todd",
    subTitle: "Senior Risk Data Analyst",
    photo: thomas,
    url: "https://www.linkedin.com/in/thomas-todd-09349140",
  },
  {
    title: "Michael Ward",
    subTitle: "Senior Risk Data Analyst",
    photo: michael,
    url: "https://www.linkedin.com/in/michael-ward-data-science/",
  },
  {
    title: "Chandan Garg",
    subTitle: "Data Scientist",
    photo: chandan,
    url: "https://www.linkedin.com/in/gargchandan",
  },
  {
    title: "Raj Lad",
    subTitle: "Senior Software Engineer",
    photo: raj,
    url: "https://www.linkedin.com/in/rajlad/",
  },
  {
    title: "Divakar Manoj",
    subTitle: "Software Engineer",
    photo: divakar,
    url: "https://www.linkedin.com/in/divakarmanoj",
  },
  {
    title: "Suhail Ranger",
    subTitle: "Software Engineer",
    photo: suhail,
    url: "https://www.linkedin.com/in/suhail-ranger-b46866209/",
  },
  {
    title: "He Gong",
    subTitle: "Software Engineer",
    photo: he,
    url: "https://www.linkedin.com/in/he-gong-b44005b1/",
  },
  {
    title: "Ryo Kato",
    subTitle: "Software Engineer",
    photo: ryo,
    url: "https://www.linkedin.com/in/ryo-kato-626068103/",
  },
  {
    title: "David Argoff",
    subTitle: "Senior Software Engineer",
    photo: david,
    url: "https://www.linkedin.com/in/dave-argoff",
  },
  {
    title: "Nipun Sud",
    subTitle: "Senior Software Engineer",
    photo: nipun,
    url: "https://www.linkedin.com/in/nipun-sud",
  },
  {
    title: "Jeffrie Joshua Lazarus George",
    subTitle: "Senior Software Engineer",
    photo: jeffrie,
    url: "https://www.linkedin.com/in/jeffriejoshua",
  },
  {
    title: "Fabricio Filipe Viapiana",
    subTitle: "Software Engineer",
    photo: fabricio,
    url: "https://www.linkedin.com/in/fabricio-filipe-viapiana",
  },
  {
    title: "Ethan Itovitch",
    subTitle: "Software Engineer",
    photo: ethan,
    url: "https://www.linkedin.com/in/ethan-itovitch",
  },
];

const TeamSection: FC<Props> = () => {
  return (
    <TeamCardSectionWrapper className="section-wrapper relative">
      <div className="wrapper-parent layout-width relative flex flex-col flex-nowrap justify-start w-full pt-28">
        <div className="team-container flex flex-col justify-between w-full">
          <p className="font-Inter font-semibold text-3xl text-blue-darken mb-10 text-center">
            Meet our team
          </p>
          <div className="flex flex-row justify-start flex-wrap mx-auto mb-16">
            {teamData.map((item, index) => (
              <Team
                key={index}
                title={item.title}
                subTitle={item.subTitle}
                photo={item.photo}
                url={item.url}
              />
            ))}
          </div>
        </div>
      </div>
    </TeamCardSectionWrapper>
  );
};

export default TeamSection;
