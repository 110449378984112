import React, { FC } from "react";
import { MainSectionWrapper } from "./mainSection.styles";
import company from "../../../images/company.svg";
// import investor1 from "../../../images/investors/investor1.svg";
// import investor2 from "../../../images/investors/investor2.svg";
// import investor3 from "../../../images/investors/investor3.svg";
// import investor4 from "../../../images/investors/investor4.svg";
// import investor5 from "../../../images/investors/investor5.svg";
// import longLine from "../../../images/investors/investor-long-line.svg";

interface Props {
  //   title: string;
}
const MainSection: FC<Props> = () => {
  return (
    <section className="section-wrapper relative" css={MainSectionWrapper}>
      <div className="curves-parent absolute top-0 left-full"></div>
      <div className="wrapper-parent layout-width bg-transparent relative w-full pt-24">
        <div className="flex flex-row justify-between w-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-20 md:mb-0">
              <p className="font-Inter font-semibold text-blue-accent text-lg content-start mb-5 md:text-base md:mb-6 md:text-center">
                About us
              </p>
              <p className="font-Inter title-parent text-blue-darken text-5xl font-semibold mb-4 md:text-4xl md:text-center md:er md:mb-6 md:mr-auto md:ml-auto">
                Customers hate waiting for their money.
              </p>
              <p className="font-Inter subtitle-parent text-blue-darken text-lg font-normal mb-8 md:tracking-tight md:text-center md:text-base md:font-normal md:mb-0 md:mr-auto md:ml-auto">
                At the same time billions of dollars are lost to fraud every
                year. Our team helped scale Coinbase, led the launch of Revolut
                US, and fought fraud at PayPal, Zelle, Uber, and Bolt. We
                started Sardine to remove the friction and risks associated with
                high velocity money movement, from banks and cards to digital
                wallets, and crypto.
              </p>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-center mx-auto">
            <img src={company} alt="developers" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
