import React, { FC } from "react";
import Carousel from "../../carousel/carousel";
import { WhySectionWrapper } from "./whySection.styles";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import soupsHead from "../../../images/company/soups_head.png";
import zahidHead from "../../../images/company/zahid_head.png";
import adityaHead from "../../../images/company/adi_head.png";
import soupsHeadMob from "../../../images/company/soups_head_mob.png";
import zahidHeadMob from "../../../images/company/zahid_head_mob.png";
import adityaHeadMob from "../../../images/company/adi_head_mob.png";

interface Props {
  //   title: string;
}
const WhySection: FC<Props> = () => {
  let isMobile = useMediaQuery("(max-width: 991.98px)");
  const whyData = [
    {
      feedback:
        "“I knew that I needed to start Sardine when I saw first hand, $1M stolen by identity fraud.”",
      hint: "I’ve worked in high-risk industries like crypto and cross-border digital banking, and personally witnessed fraud cases that threatened the very survival of these companies. What I also observed were major gaps in today’s KYC processes.",
      name: "Soups Ranjan",
      title: "Co-Founder and CEO",
      photo: "",
      logo: "",
      image: isMobile ? soupsHeadMob : soupsHead,
      mobileImage: true,
    },
    {
      feedback:
        "“The first reaction of any company that’s hit by fraud is to add friction to the customer experience.”",
      hint: "I’ve seen so many creative instances of fraud, from mobile device farms to IoT cameras used to DDoS payment gateways...Our goal at Sardine, is to enable you to focus on adding features and growing your business, without worrying about fraud.",
      name: "Zahid Shaikh",
      title: "Head of Product",
      photo: "",
      logo: "",
      image: isMobile ? zahidHeadMob : zahidHead,
      mobileImage: true,
    },
    {
      feedback:
        "“Getting the fraud and regulatory compliance infrastructure right is critical from day one.”",
      hint: "At Revolut, the popular European neobank, our team spent months obsessing over the remaining details to go live. Every time we felt ready for launch, we realized that we had to solve for new gaps and more edge cases of risk and fraud.",
      name: "Aditya Goel",
      title: "Co-Founder, Head of Crypto & Payments",
      photo: "",
      logo: "",
      image: isMobile ? adityaHeadMob : adityaHead,
      mobileImage: true,
    },
  ];
  return (
    <WhySectionWrapper className="section-wrapper relative pt-24">
      <div className="curves-parent-left absolute top-0 left-0"></div>
      <div className="curves-parent-right absolute top-0 left-full"></div>
      <div className="wrapper-parent layout-width w-full">
        <div className="flex flex-col justify-start">
          <p className="title-parent font-Inter text-blue-darken tracking-tight text-3xl font-semibold mx-auto mb-6 md:text-3xl md:text-center md:tracking-tighter md:mb-0 md:mr-auto md:ml-auto md:px-2">
            Why we started Sardine
          </p>
          <Carousel data={whyData} />
        </div>
      </div>
    </WhySectionWrapper>
  );
};

export default WhySection;
